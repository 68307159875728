import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

const ContactSection = () => {

  const [formState, setFormState] = useState('init');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = [...formData.keys()].reduce((obj, key) => {
        const value = formData.getAll(key).join(', ').trim();
        if (value) {
            obj[key] = value;
        }
        return obj;
    }, {});

    if (data.firstName) return;

    setFormState('submitting');

    await fetch(`https://api.${window.location.host}/contact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
  
    e.target.reset();
    setFormState('submitted');
  };

  return (
    <>
      <div className="contact-section section" id="contact">
        <div className="sub-container">
          <div className="flex space-between">
            <form onSubmit={handleSubmit}>
              <h6>First Time with Us?</h6>
              <div className="flex ">
                <input name="newPatient" type="radio" id="yes" value="yes" />
                <label className="pl-1" htmlFor="yes">
                  Yes
                </label>
              </div>
              <div className="flex ">
                <input name="newPatient" type="radio" id="no" value="No" />
                <label className="pl-1" htmlFor="no">
                  No
                </label>
              </div>
              <div className="form">
                <input name="name" type="text" placeholder="Your Name" required />
                <input name="email" type="email" placeholder="Your Email" required />
                <input
                  name="time"
                  type="datetime-local"
                  id="birthdaytime"
                  placeholder="Select Time and Date"
                  required
                />
                <textarea name="message" placeholder="Your Message" required></textarea>
                {formState === 'submitted' ? 
                  <p>Message received! Somebody from our office will be in touch.</p> : 
                  <button type="submit" className="btn dark-golden" disabled={formState === 'submitting' ? true : undefined}>{formState === 'submitting' ? 'Submitting...' : 'Submit'}</button>
                }
              </div>
            </form>
            <div className="pl-3">
              <h6>Appointments</h6>
              <h3 style={{ maxWidth: "460px" }}>
                Going for Dental Visits Should Be a Breeze
              </h3>
              <p style={{ maxWidth: "586px" }}>
                We encourage you to visit us at least twice a year to check for
                progress and any other potential issues that may occur after the
                procedure. This will also help us maintain your nice smile. Call
                us to learn more about our services.
              </p>
              <StaticImage src="../images/waves.png" alt="waves" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactSection
