import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import fb from "../images/Facebook.svg"
import insta from "../images/Instagram.svg"
import youtube from "../images/Youtube.svg"

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className=" relative">
          <div className=" sub-container">
            <div className="flex align-center space-between">
              <h4 style={{ maxWidth: "400px" }}>
                For Directions and for any questions
              </h4>
              <div>
                <a
                  href="tel:619-462-9933"
                >
                  <button className="btn" style={{ padding: "15px 40px" }}>
                    Call Us
                  </button>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="flex image" style={{ justifyContent: "flex-end" }}>
            <StaticImage
              src="../images/golden-wave.png"
              alt="golden wave"
              className="img"
            />
          </div> */}
        </div>
        <hr className="golden" />
        <div className="flex space-between sub-container align-center">
          <div className="" style={{ paddingTop: "40px" }}>
            <StaticImage src="../images/logo.png" alt="logo dinkha dental" />

            <p
              className="grey-p"
              style={{ marginBottom: "0px", paddingLeft: "30px" }}
            >
              
            </p>
            <a href="https://www.google.com/maps/place/Dinkha+Dental/@32.7575638,-117.0165634,17z/data=!4m13!1m7!3m6!1s0x80d9573733c60daf:0x545eaa02da373b17!2s4323+Palm+Ave+C,+La+Mesa,+CA+91941!3b1!8m2!3d32.7575593!4d-117.0143747!3m4!1s0x80d957373350329f:0x2131bda9c043fe4f!8m2!3d32.7575921!4d-117.0143719"
              target="_blank"
              className="grey-p"
              style={{
                display: 'block',
                marginBottom: "0px",
                marginTop: "0px",
                paddingLeft: "30px",
              }}
            >
              4323 Palm Ave, Ste C<br />
              La Mesa, California 91941
            </a>
            <a href="mailto: info@dinkhadental.com"
              className="grey-p"
              style={{
                display: 'block',
                marginBottom: "0px",
                marginTop: "0px",
                paddingLeft: "30px",
              }}
            >
              info@dinkhadental.com
            </a>
            <a href="tel:619-462-9933"
              className="grey-p"
              style={{
                display: 'block',
                marginBottom: "0px",
                marginTop: "0px",
                paddingLeft: "30px",
              }}
            >
              619-462-9933
            </a>
          </div>
          <div>
            <p style={{ color: "#cccccc" }}>Follow Us</p>
            <img
              src={fb}
              alt="facebook icon"
              style={{ paddingRight: "10px" }}
            />
            <img
              src={insta}
              alt="instagram icon"
              style={{ paddingRight: "10px" }}
            />
            <img src={youtube} alt="youtube icon" />
          </div>
        </div>
        <div style={{ padding: "50px 0 10px 0  " }}>
          <hr className="golden" />
          <p style={{ color: "#cccccc", textAlign: "center" }}>
            Dinkha Dental 2021. All Rights Reserved
          </p>
        </div>
      </div>
    </>
  )
}

export default Footer
