import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const FindUS = () => {
  return (
    <div>
      <div className="sub-container section">
        <div className="flex space-between mobile">
          <div className="map">
            <h3>Find Us</h3>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3355.3078971185246!2d-117.01656338391388!3d32.75756379228238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d957373350329f%3A0x2131bda9c043fe4f!2sDinkha%20Dental!5e0!3m2!1sen!2sus!4v1624507795542!5m2!1sen!2sus"
              width="530"
              height="450"
              title="Map"
              allowfullscreen=""
              style={{ border: "none" }}
              loading="lazy"
            ></iframe>
          </div>
          <div className="address ">
            <StaticImage
              src="../images/waves.png"
              alt="waves"
              className="img"
            />
            <h6>La Mesa</h6>
            <p><a style={{color: 'inherit'}} href="https://www.google.com/maps/place/Dinkha+Dental/@32.7575638,-117.0165634,17z/data=!4m13!1m7!3m6!1s0x80d9573733c60daf:0x545eaa02da373b17!2s4323+Palm+Ave+C,+La+Mesa,+CA+91941!3b1!8m2!3d32.7575593!4d-117.0143747!3m4!1s0x80d957373350329f:0x2131bda9c043fe4f!8m2!3d32.7575921!4d-117.0143719" target="_blank">4323 Palm Ave, Ste C La Mesa, CA 91941</a></p>
            <p>(619) 462-9933</p>
            <div className="flex direction align-center">
              <StaticImage src="../images/map.png" alt="map" className="img" />
              <p><a style={{color: 'inherit'}} href="https://www.google.com/maps/place/Dinkha+Dental/@32.7575638,-117.0165634,17z/data=!4m13!1m7!3m6!1s0x80d9573733c60daf:0x545eaa02da373b17!2s4323+Palm+Ave+C,+La+Mesa,+CA+91941!3b1!8m2!3d32.7575593!4d-117.0143747!3m4!1s0x80d957373350329f:0x2131bda9c043fe4f!8m2!3d32.7575921!4d-117.0143719" target="_blank">Get Directions</a></p>
              <p><a style={{color: 'inherit'}} href="mailto:info@dinkadental.com" style={{color: 'inherit'}}>info@dinkadental.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindUS