import React from "react"
import Footer from "./footer.component"
//import BlogRoll from "./blogroll.component"
import FindUS from "./FindUs.component"
import ContactSection from "./contactSection.component"
import {Helmet} from 'react-helmet';
import logo from "../images/logo.png"

const Layout = ({ children }) => {
  return (
    <div>
      <Helmet>
        <meta name="google-site-verification" content="TjrPG1sDRk4tcHvD2R2gX5GkVL6DO0k6sQxNkxZQgCA" />
        <meta name="robots" content="noodp"/>
        <img itemprop="logo" src={logo} alt="Dinkha Dental Logo" />
        <meta itemprop="latitude" content="32.757977970979496" />
        <meta itemprop="longitude" content="-117.01443537301047 " />
        <script type="application/ld+json">{
          `{
            "@context": "https://schema.org/",
            "@type": "LocalBusiness",
            "name": "Dinkha Dental",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "La Mesa",
              "addressRegion": "CA",
              "postalCode": "91941",
              "streetAddress": "4323 Palm Ave"
            },
            "telephone": "(619) 462-9933",
            "hasMap": "https://goo.gl/maps/9pA8h17oCjki7p12A"
          }`
        }
        </script>
      </Helmet>
      {children}
      <ContactSection />
      <FindUS />
      <Footer />
    </div>
  )
}

export default Layout
